<template lang="pug">
div.flex.horizontal-expand.horizontal-centralize.vertical-centralize()
	div.flex.horizontal-expand.expand-vh-vertical(style="min-height: 100vh; background: linear-gradient(to right, #f2f2f2, #e5e5e5);; display: flex; width: 100%; justify-content: center;")
		v-flex.pa-3(xs12 md10 v-if="passo===1")
			v-form(ref="form")
				div.flex.horizontal-expand.vertical-centralize.column
					div.flex.expande-horizontal
						img(height="60" src="login.png" style="padding: 12px; padding-top: 0px; border-radius: 6px;")
						div
							h4.fonte.mb-0 Cadastro Médico
							span.grey--text.mb-6.fonte Informe seus dados abaixo.
						v-spacer
						v-btn(icon @click="$router.push('/')" class="elevation-1" :color="$theme.primary" dark depressed)
							v-icon mdi-close
					v-divider 

					div.expande-horizontal.column
						v-list(color="transparent")
							v-list-item.px-0.mx-0
								v-avatar.mr-2(rounded color="#fff")
									v-icon(:color="$theme.primary") mdi-account-circle
								v-list-item-content 
									v-list-item-title 
										span.fonte.black--text Dados Pessoais
									v-list-item-subtitle 
										span.fonte.grey--text Informe seus dados abaixo

					div.expande-horizontal.wrap
						v-flex(xs12 md4).pr-3
							v-text-field(
								filled
								label="Nome Completo"
								v-model="payload.nome"
								placeholder="Digite o seu nome completo"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
							)
						v-flex(xs12 md4).pr-3
							v-text-field(
								filled
								label="E-mail"
								v-model="payload.email"
								placeholder="Informe o seu melhor e-mail"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
							)
						v-flex(xs12 md4).pr-3
							v-text-field(
								filled
								label="Telefone"
								v-model="payload.telefone"
								placeholder="Informe o seu telefone"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
								v-mask="['(##) #####-####', '(##) ####-####']"
							)
						v-flex(xs12 md4).pr-3
							v-text-field(
								filled 
								label="CPF"
								v-model="payload.cpf"
								placeholder="000.000.000-00"
								:rules="[v => !!v || 'Preencha aqui']"
								v-mask="['###.###.###-##']"
								:color="$theme.primary"
							)
					div.expande-horizontal.column
						v-list(color="transparent")
							v-list-item.px-0.mx-0
								v-avatar.mr-2(rounded color="#fff")
									v-icon(:color="$theme.primary") mdi-medical-bag
								v-list-item-content 
									v-list-item-title 
										span.fonte.black--text Dados Profissionais
									v-list-item-subtitle 
										span.fonte.grey--text Informe seus dados profissionais abaixo
					div.expande-horizontal.wrap
						v-flex(xs12 md4).pr-3
							v-text-field(
								filled
								label="CRM"
								v-model="payload.professional_record.number"
								placeholder="Informe o seu CRM"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
							)
						v-flex(xs12 md4).pr-3
							v-text-field(
								filled
								label="UF CRM"
								v-model="payload.professional_record.uf"
								placeholder="Informe o UF do seu CRM"
								v-mask="['AA']"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
							)
						v-flex(v-if="false" xs12 md4).pr-3
							v-text-field(
								filled 
								label="CNS"
								v-model="payload.cns"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
							)
						v-flex(v-if="!payload.specialty_not_found" xs12 md4).pr-3
							v-autocomplete(
								filled
								label="Especialidade"
								:items="specialties"
								v-model="payload.specialty"
								item-text="nome"
								return-object
								hide-details
								placeholder="Informe a sua especialidade"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
							)
						v-flex(xs12 md4).pr-3
							v-checkbox(
								filled
								label="Não encontrei minha especialidade"
								v-model="payload.specialty_not_found"
								item-text="nome"
								:color="$theme.primary"
							)
						v-flex(v-if="payload.specialty_not_found" xs12 md8).pr-3
							v-text-field(
								filled
								autofocus
								hide-details
								label="Informe sua Especialidade manualmente"
								placeholder="Informe a sua especialidade manualmente"
								v-model="payload.new_specialty"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
							)
							

					div.expande-horizontal.column
						v-list(color="transparent")
							v-list-item.px-0.mx-0
								v-avatar.mr-2(rounded color="#fff")
									v-icon(:color="$theme.primary") mdi-map-marker-radius
								v-list-item-content 
									v-list-item-title 
										span.fonte.black--text Endereço
									v-list-item-subtitle 
										span.fonte.grey--text Informe seu endereço abaixo
					div.expande-horizontal.wrap
						v-flex(xs12 md2).pr-2
							v-text-field(
								dense
								label="CEP"
								v-model="payload.address.zipcode"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
								filled
							)
						v-flex(xs12 md8).pr-2
							v-text-field(
								dense
								label="Endereço"
								v-model="payload.address.street"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
								filled
							)
						v-flex(xs12 md2).pr-2
							v-text-field(
								dense
								label="Número"
								v-model="payload.address.number"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
								filled
							)
						v-flex(xs12 md4).pr-2
							v-text-field(
								label="Bairro"
								v-model="payload.address.neighborhood"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
								filled
							)
						v-flex(xs12 md4).pr-2
							v-text-field(
								label="Cidade"
								v-model="payload.address.city"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
								filled
							)
						v-flex(xs12 md3).pr-2
							v-text-field(
								label="Estado"
								v-model="payload.address.state"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
								filled
							)
						v-flex(xs12 md1).pr-2
							v-text-field(
								label="UF"
								v-model="payload.address.uf"
								:rules="[v => !!v || 'Preencha aqui']"
								:color="$theme.primary"
								filled
								v-mask=['XX']
							)
						v-flex(xs12).pr-2
							v-text-field(
								dense
								label="Complemento"
								v-model="payload.address.complement"
								:color="$theme.primary"
								filled
							)
					div.expande-horizontal.column
						v-list(color="transparent")
							v-list-item.px-0.mx-0
								v-avatar.mr-2(rounded color="#fff")
									v-icon(:color="$theme.primary") mdi-shield-key
								v-list-item-content 
									v-list-item-title 
										span.fonte.black--text Senha de Acesso
									v-list-item-subtitle 
										span.fonte.grey--text Informe uma senha para que você acesse a plataforma
									v-list-item-subtitle
										span.fonte.red--text {{ erro }}

					div.expande-horizontal.wrap
						v-flex(xs12 md4).pr-2
							v-text-field(
									dense
									label="Senha"
									filled

									v-model="payload.senha"
									:rules="[v => !!v || 'Preencha este campo']"
									placeholder="******"
									type="password"
									:color="$theme.primary"
							)
						v-flex(xs12 md4).pr-2
								v-text-field(
										dense
										filled

										label="Confirme a senha"
										v-model="confirm_senha"
										:rules="[v => !!v || 'Preencha aqui']"
										placeholder="******"
										type="password"
										:color="$theme.primary"
								)


					//- div.mb-6.expande-horizontal.centraliza
					//-   v-switch(class="pa-2"  color="light-green" outlined v-model="termos" label="Ao criar a conta você concorda com os nossos termos de uso.")
					div.mb-6.expande-horizontal.centraliza
						v-btn.fonte(
							:disabled="senhaOk ? false : true"
							rounded
							depressed
							outlined
							:loading="loading"
							class="fonte"
							@click="valida_form"
							:color="$theme.primary"
						)
							span.fonte Finalizar e Cadastrar

					div.mb-6.flex.horizontal-expand.column
						v-btn.fonte(
								@click="$router.push('/')"
								text
								block

								:color="$theme.primary"
						)
							span.black--text Tem um cadastro? Faça Login

					//- h3.margin-vertical.mb-12.fonte.text-center Ao criar uma conta, você concorda com a nossa Política de Privacidade.
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import validarCPF from "validar-cpf";

export default {
	props: ["sponsor_id"],
	data() {
		return {
			passo: 1,
			loading: false,
			modal: false,
			termos: true,
			erro: "",
			confirm_senha: "",
			sex_options: [
				{
					name: "Masculino",
					value: "M"
				},
				{
					name: "Feminino",
					value: "F"
				}
			],
			ethnicity_options: [
				"Branca",
				"Não Informado",
				"Preta",
				"Parda",
				"Amarela",
				"Indígena"
			],
			specialties: [],
			payload: {
				transaction_senha: "",
				new_specialty: "",
				specialty_not_found: false,
				senha: "",
				sponsor: {
					_id: "nosponsor"
				},
				address: {},
				professional_record: {
					advice: "CRM",
					crm: "",
					uf: "",
					number: ""
				},
			},
			senhaOk: false
		};
	},
	watch: {
		confirm_senha(val) {
			if (val === this.payload.senha) {
				this.senhaOk = true;
				this.erro = "";
			} else {
				this.senhaOk = false;
				this.erro = "Senhas não correspondem";
			}
		}
	},
	methods: {
		...mapActions(["createUser", "createGlobalMessage", "enableLoading", "disableLoading"]),
		valida_form() {
			this.loading = true;
			if (this.$refs.form.validate()) {
				if (validarCPF(this.payload.cpf)) {
					this.loading = true;
					this.$run("signup", this.payload, 'doctor')
						.then(res => {
							this.loading = false;
							this.createGlobalMessage({
								timeout: 6000,
								type: "success",
								message: "Cadastro realizado com sucesso"
							});
							this.$router.push("/");
						})
						.catch(e => {
							this.loading = false;
							this.createGlobalMessage({
								timeout: 6000,
								type: "error",
								message: e.response.data.message
							});
						});
				} else {
					this.createGlobalMessage({
						timeout: 6000,
						type: "error",
						message: "CPF inválido"
					});
				}
			}
		}
	},
	created() {
		this.enableLoading();
		this.$run('specialty/list', {}, 'public')
			.then(res => {
				this.disableLoading();
				this.specialties = res.data
			})
			.catch(() => {
				this.disableLoading();
				this.createGlobalMessage({
					timeout: 6000,
					type: "error",
					message: 'Rede instável, tente novamente dentro de alguns momentos'
				});
			})
	}
};
</script>
